import React from 'react'
import AppleStoreBadgeEn from '../images/download_on_the_app_store_badge_en.svg'
import AppleStoreBadgeNl from '../images/download_on_the_app_store_badge_nl.svg'
import GooglePlayBadgeNl from '../images/get_it_on_google_play_badge_web_color_nl.svg'
import GooglePlayBadgeEn from '../images/get_it_on_google_play_badge_web_color_en.svg'
import I18n from 'i18n'
import FeatureFlag, { PLAY_STORE_LINKS_FLAG } from 'components/feature_flags/FeatureFlag'

const NativeAppStoreLinks = () => {
  const locale = I18n.locale

  const appleStoreBadge = locale === 'nl' ? AppleStoreBadgeNl : AppleStoreBadgeEn
  const googlePlayBadge = locale === 'nl' ? GooglePlayBadgeNl : GooglePlayBadgeEn

  const appleStoreUrl = process.env.NATIVE_APP_APPLE_STORE_URL
  const googlePlayUrl = process.env.NATIVE_APP_GOOGLE_PLAY_URL

  return (
    <div className='native-app-store-links'>
      <a href={appleStoreUrl} target='_blank' rel='noopener noreferrer' className='store-badge apple-store'>
        <img src={appleStoreBadge} alt='App Store' />
      </a>
      <FeatureFlag name={PLAY_STORE_LINKS_FLAG}>
        <a href={googlePlayUrl} target='_blank' rel='noopener noreferrer' className='store-badge-google google-play'>
          <img src={googlePlayBadge} alt='Google Play' />
        </a>
      </FeatureFlag>
    </div>
  )
}

export default NativeAppStoreLinks
